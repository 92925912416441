:root {
  --background-color-light: #ffffff; /* Default background color for light mode */
  --background-color-dark: #333333; /* Background color for dark mode */
}


.header-container{
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid black;
    justify-content: space-between;
    height:80px;
    position: relative;
    background-color: var(--background-color-light);
    transition: background-color 0.5s ease; 
}

.dark-mode .header-container{
    background-color: var(--background-color-dark);
    border-bottom: 1px solid white;
    transition: background-color 0.5s ease;
}

.text-container{
    position: relative;;
    width: 25%;
    /* border: 1px solid red; */
}

.signin-container{
    /* border: 1px solid red; */
    margin-top:1%;
    margin-left:5%;
    position: relative;
    display: flex;
}

.darkmode-container{
    width: 5%;
    position: relative;
    /* border: 1px solid red; */
    align-items: center;
    padding-top: 30px;
}