:root {
  --background-color-light: #ffffff; /* Default background color for light mode */
  --background-color-dark: #2c3237; /* Background color for dark mode */

  --text-color-light: #333333; /* Text color for light */

  --text-color-dark: #ffffff;

}

.content-container {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-light);
    transition: background-color 0.5s ease; 

        /* position: relative; */
}

.dark-mode .content-container {
    background-color: var(--background-color-dark);
    transition: background-color 0.5s ease;
}

.main-content {
    margin-left: 25%;
    /* border-radius: 3%; */
    width: 50%;
    display: flex;
    position: relative;
    
}

.header {
    position: relative;
    width: 100%;
    color: var(--text-color-light);
}

.dark-mode .header{
    color: var(--text-color-dark);
}

.intro-container{
    width: 100%;
}

.bio-container{
    /* display: flex; */
    width: 100%;
    text-align: left;
    color: var(--text-color-light);
    /* border : 1px solid blue; */
}

.dark-mode .bio-container{
    color: var(--text-color-dark);
}

.image-container{
    left: 50%;
    margin-left: 25%;
    margin-top: 3%;
    border-radius: 5px;
    /* border: 1px solid blue; */
}

.socials-container{
    margin-top: 10px;
    justify-content: space-between;
    padding-left: 35%; 
    padding-right: 35%;   
    display: flex;
}

.dark-mode .icon{
    color: white
}

.icon{
    color: black;
}

.icon:hover{
    color: purple;
}

.experience-header {
    /* border: 3px solid green; */
    width: 50%;
    margin-left: 25%;
    text-align: left;
    display: flex;
    color: black;
    /* right: 10px; */
}

.dark-mode .experience-header{
    color: white;
}

.experiences-container{
    margin-top: 20px;
    margin-left: 25%;
    width: 50%;
    color: var(--text-color-light);
}

.dark-mode .experiences-container{
    color: var(--text-color-dark);

}

.experience-company {
    /* text-align: left; */
    font-weight: bold;
    display: flex;
    justify-content: space-between;

}

.experience-title{
    /* text-align: left; */
    font-style: italic;
    display:flex;
    justify-content: space-between;
}

.highlight{
    color: purple;
}

.dark-mode .highlight{
    color: rgb(157,101,255);
}

.edit-container{
    display: flex;
    justify-content: space-between;

}

.modal-container{
    /* border: 1px solid; */
    max-width: 600px;
    width: 100%;
    position: fixed;
    /* top: 40%;
    left: 50%; */
    /* transform: translate(-30%, -30%) */
    /* display: flex; */
    border-radius: 5px;
    background-color: rgba(0, 160, 178, 0.5);
    backdrop-filter: blur(5px);
}

.modal-title {
    display: flex;
    justify-content: center; 
    /* align-items: center; */
}

.form-container{
    /* display: grid; */
    padding: 10px;
    justify-content: space-between;

}

.submit-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel-btn{
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    /* display: flex; */
    /* display: flex; */
}