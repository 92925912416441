/* Footer.css */

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2c3237;
  color: #fff;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display:flex;
}
